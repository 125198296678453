<template>
  <Header
      :loading="loading"
      :status="status"
      @onLanguageChanged="onLanguageChanged"
  />

  <CarouselAccueil/>

  <Loader v-if="instance === null"/>

  <router-view v-slot="{ Component }">
    <component :is="Component"
               v-if="instance !== null"
               :configuration="configuration"
               :instance="instance"
               :price="price"
               :langueActive="langueActive"
               @onInstanceUpdated="onInstanceUpdated"
               @onLoadingFinished="onLoadingFinished"
               @onLoadingStarted="onLoadingStarted"
    />
  </router-view>

  <!--  <DebugBar v-if="instance !== null" :instance="instance" :price="price"/>-->

  <Footer/>
</template>

<script lang="ts">
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
// import DebugBar from '@/components/DebugBar.vue';
import { defineComponent } from 'vue';
import AxiosFactory, { axios } from '@/factory/AxiosFactory';
import { ApiResponse } from '@/models/ApiResponse';
import { Instance, InstanceInterface } from '@/models/Instance';
import { InstancePrice } from '@/models/InstancePrice';
import { Configuration } from '@/models/Configuration';
import { isConfiguration } from '@/shared/Helpers';
import { QueryParser } from '@/shared/QueryParser';
import { PreloadQuery } from '@/models/PreloadQuery';
import Loader from '@/components/Loader.vue';
import CarouselAccueil from '@/components/CarouselAccueil.vue';
import { InstancePreloader } from '@/shared/InstancePreloader';

export default defineComponent({
  name: 'root',
  components: {
    CarouselAccueil,
    Header,
    Footer,
    // DebugBar,
    Loader,
  },
  data() {
    return {
      loading: false,
      status: '',
      instance: null as null | Instance,
      configuration: null as null | Configuration,
      price: {
        price: 0,
        detail: {
          aircraftWeight: 0,
          aircraftParking: 0,
          aircraftAssistance: 0,
          passengers: 0,
          services: 0,
        },
      } as InstancePrice,
      axios: axios,
      langueActive: 'fr'
    };
  },
  methods: {
    onLoadingStarted() {
      this.loading = true;
      this.status = '';
    },
    onLoadingFinished(message: string) {
      this.loading = false;
      this.status = message;

      setTimeout(() => this.status = '', 1500);
    },
    onInstanceUpdated(instance: Instance) {
      this.instance = instance;

      this.axios.get(`${import.meta.env.VITE_API_URL}/configurator/price`, AxiosFactory.getRequestConfig())
          .then(({data}: { data: ApiResponse | InstancePrice }) => {
            if ('type' in data && data.type === 'error') {
              // console.log('error', data.message);
              this.price = null;
            } else if ('price' in data) {
              this.price = data;
            }
          });
    },
    onLanguageChanged(locale: string) {
      this.$i18n.locale = locale;
      this.langueActive = locale;

      window.localStorage.setItem('locale', locale);

      window.location.reload();
    },
  },
  mounted() {
    const savedLocale = window.localStorage.getItem('locale');
    if (savedLocale && savedLocale.length > 0) {
      this.langueActive = savedLocale;
      this.$i18n.locale = savedLocale;
    }

    this.instance = null;

    let queryParams: PreloadQuery | null = null;
    const queryParamsCount = Object.keys(this.$route.query).length;

    if (queryParamsCount > 0) {
      queryParams = QueryParser.parse(this.$route.query);

      this.$router.push({path: '/', replace: true});
    }

    this.axios.get(`${import.meta.env.VITE_API_URL}/configuration/slim`)
        .then(({data}: { data: ApiResponse | Configuration }) => {
          if ('type' in data && data.type === 'error') {
            console.log(`Couldn't retrieve App configuration: ${data.message}`);
          } else if (isConfiguration(data)) {
            this.configuration = data;
          }
        });

    this.axios.get(`${import.meta.env.VITE_API_URL}/configurator`, AxiosFactory.getRequestConfig())
        .then(({data}: { data: ApiResponse | InstanceInterface }) => {
          if ('type' in data && data.type === 'error') {
            this.onLoadingFinished(data.message);
          } else if ('session' in data) {
            this.onLoadingFinished(`Configurator instance loaded successfully (Session ID: ${data.session})`);
            let instance = Instance.fromApiResponse(data);

            if (queryParamsCount > 0 && queryParams !== null) {
              InstancePreloader.preload(instance, QueryParser.sanitize(queryParams)).then((instance: Instance) => {
                this.onInstanceUpdated(instance);

                this.onLoadingFinished('Configurator instance pre-loaded successfully');
              });
            }

            this.onInstanceUpdated(instance);

            window.sessionStorage.setItem('configurator_sess_id', data.session);
          }
        });
  }
});

</script>

<style lang="scss">
@font-face {
  font-family: kozgoregular;
  src: url('@/assets/fonts/KozGoPr6NRegularLightweight.otf');
}

@font-face {
  font-family: kozgoextralight;
  src: url('@/assets/fonts/KozGoPr6NExtraLightLightweight.otf');
}

@font-face {
  font-family: kozgomedium;
  src: url('@/assets/fonts/KozGoPr6NMediumLightweight.otf');
}

body {
  font-family: kozgoregular !important;
}

html, body {
  height: 100%;
}

.fv-plugins-message-container > .fv-help-block > span {
  color: red;
}
</style>
