import { Configuration } from '@/models/Configuration';
import { ApiResponse } from '@/models/ApiResponse';

export function isConfiguration(data: object): data is Configuration {
  return 'deicingAvailable' in data;
}

export function isApiResponse(data: object): data is ApiResponse {
  return 'type' in data && 'message' in data;
}

export function parseDate(input: string, format: string): Date | null {
  format = (format || 'yyyy-mm-dd').toLowerCase(); // default format

  const parts = input.match(/(\d+)/g);

  if (parts === null) {
    return null;
  }

  const fmt: Record<string, number> = {};
  let i = 0;

  // extract date-part indexes from the format
  format.replace(/(yyyy|dd|mm|hh|ii)/g, (part: string): string => {
    fmt[part] = i++;

    return '';
  });

  const dateString = [parts[fmt['yyyy']], parts[fmt['mm']], parts[fmt['dd']]].filter(x => x !== undefined).join('-');
  const timeString = [parts[fmt['hh']], parts[fmt['ii']]].filter(x => x !== undefined).join(':');

  const date = new Date(`${dateString} ${timeString}`);

  if (isValidDate(date)) {
    return null;
  }

  return date;
}

export function isValidDate(d: Date | Record<string, unknown>) {
  return d instanceof Date && !isNaN(d.getTime());
}
