import { Aircraft } from '@/models/Aircraft';
import { Airport } from '@/models/Airport';
import { InstanceServices, InstanceServicesInterface } from '@/models/InstanceServices';
import { TaxiType } from '@/models/TaxiType';
import { DeicingType } from '@/models/DeicingType';
import { DeicingProduct } from '@/models/DeicingProduct';

export interface InstanceInterface {
  id: number;
  aircraft: Aircraft | null;
  departure: Airport | null;
  destination: Airport | null;
  seats: number;
  departureDate: Date;
  arrivalDate: Date;
  passengers: number;
  disabledPassengers: number;
  session: string;
  createdAt: Date;
  services: InstanceServicesInterface
}

export class Instance implements InstanceInterface {
  id = 0;
  aircraft: Aircraft | null = null;
  departure: Airport | null = null;
  destination: Airport | null = null;
  seats = 0;
  departureDate: Date = new Date();
  arrivalDate: Date = new Date();
  passengers = 0;
  disabledPassengers = 0;
  session = '';
  createdAt: Date = new Date();
  services: InstanceServices = new InstanceServices();

  constructor() {
    this.reset();
  }

  public static fromApiResponse(instanceApiResponse: InstanceInterface): Instance {
    const instance = new this();

    instance.aircraft = instanceApiResponse.aircraft;
    instance.departure = instanceApiResponse.departure;
    instance.destination = instanceApiResponse.destination;
    instance.seats = instanceApiResponse.seats;
    instance.passengers = instanceApiResponse.passengers;
    instance.disabledPassengers = instanceApiResponse.disabledPassengers;
    instance.arrivalDate = new Date(instanceApiResponse.arrivalDate);
    instance.departureDate = new Date(instanceApiResponse.departureDate);
    instance.services.gpu = instanceApiResponse.services.gpu;
    instance.session = instanceApiResponse.session;
    instance.services.runwayLighting = instanceApiResponse.services.runwayLighting;
    instance.services.taxiPassengers = instanceApiResponse.services.taxiPassengers;
    instance.services.taxiType = instanceApiResponse.services.taxiType;
    instance.services.deicingType = instanceApiResponse.services.deicingType;
    instance.services.deicingProduct = instanceApiResponse.services.deicingProduct;
    instance.services.wcEmptying = instanceApiResponse.services.wcEmptying;
    instance.services.cleaning = instanceApiResponse.services.cleaning;
    instance.services.elevatingCart = instanceApiResponse.services.elevatingCart;
    instance.services.luggageCart = instanceApiResponse.services.luggageCart;
    instance.services.catering = instanceApiResponse.services.catering;

    return instance;
  }

  public reset() {
    this.aircraft = null;
    this.departure = null;
    this.destination = null;
    this.seats = 0;
    this.passengers = 0;
    this.disabledPassengers = 0;
    this.arrivalDate = new Date();
    this.departureDate = new Date();
    this.services.gpu = 0;
    this.services.runwayLighting = false;
    this.services.taxiPassengers = 0;
    this.services.taxiType = TaxiType.None;
    this.services.deicingType = DeicingType.None;
    this.services.deicingProduct = DeicingProduct.None;
    this.services.wcEmptying = false;
    this.services.cleaning = false;
    this.services.elevatingCart = 0;
    this.services.luggageCart = 0;
    this.services.catering = '';
  }
}
