<!--
<template>
  <div id="Diapo" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active ">
        <img src="@/assets/images/avion-aeroport-rouen.webp" alt="avion">
        <div class="carousel-caption caption-diapo">
          <h1 class="title-diapo">Aéroport Rouen Vallée de Seine</h1>
          <p class="p-diapo">
            L’aéroport Rouen Vallée de Seine est au cœur de la Métropole Rouennaise, forte de 500 000 habitants
            dans un bassin de vie de 800 000 habitants (soit près de 25% de la population régionale) avec 45 000
            entreprises et 300 000 emplois dans les domaines industriels, tertiaires et portuaires.
          </p>
          <a class="btn btn-primary">Aéroport</a>
        </div>
      </div>
    </div>
  </div>
</template>
-->
<template>
  <div class="breadcrumbs_area">

    <div class="container container65">
      <div class="row">
        <div class="col-lg-5 col-md-6">
          <div class="breadcrumb_content orange wow fadeInUp">
            <h1 class="breadcrumb_title text-white text-uppercase">{{$t('bandeau.titre')}}</h1>
            <p class="text-justify text-white">{{$t('bandeau.description')}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CarouselAccueil'
});
</script>

<style lang="scss" scoped>

#Diapo .carousel-item {
  height: 90vh !important;

  img {
    height: 90vh !important;
    width: 100%;
    object-fit: cover;
  }
}

.carousel-caption {
  top: 51%;
  right: 16%;
  left: 16%;
}

.title-diapo {
  font-family: kozgoextralight !important;
  font-size: 3.5rem;
}

.p-diapo {
  font-family: kozgoextralight !important;
  font-size: 1.3rem;
  text-align: center;
  padding-left: 67px;
  padding-right: 67px;
}

.caption-diapo a {
  border-radius: 0 !important;
  font-size: 1.2rem !important;
  letter-spacing: 1px;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-top: 10px;
  background-color: #2db5df;
  border-color: #2db5df;
}

.caption-diapo a:hover {
  background-color: #2d3d51 !important;
  border-color: #2d3d51 !important;
}

.breadcrumbs_area {

  background-image: url(@/assets/images/avion-aeroport-rouen.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /*height: 700px;*/
  padding: 208px 0;
  display: flex;
  align-items: center;

  .breadcrumb_content {
    background: rgba(216, 64, 24, 0.8);
    padding: 25px 50px 25px 0;
    position: relative;

    .breadcrumb_title {
      text-align: left;
      font-size: 32px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: -100%;
      background: rgba(216, 64, 24, 0.8);
    }
  }
}

@media only screen and (max-width: 1199px) {
  .breadcrumbs_area {
    padding: 115px 0;
  }
  .breadcrumbs_area .breadcrumb_content .breadcrumb_title {
    font-size: 25px;
  }
}

@media (max-width: 667px) {
  .breadcrumbs_area {
    padding: 55px 0 80px 0;
  }
  .breadcrumbs_area .breadcrumb_content .breadcrumb_title {
    font-size: 17px;
    margin-bottom: 6px;
  }
  .breadcrumbs_area .breadcrumb_content p {
    font-size: 12px;
  }
  .breadcrumbs_area .breadcrumb_content {
    padding: 15px 15px 5px 0;
  }

}

@media (max-width: 568px) {
  .breadcrumbs_area .breadcrumb_content .breadcrumb_title {
    font-size: 15px;
  }
}

</style>
