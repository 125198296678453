<template>
  <footer class="bg-footer">
    <div class="footer1">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4">
            <div class="flex-center"><img alt="Logo aéroport" src="@/assets/images/logo-aeroport.webp"></div>
          </div>
          <div class="col-md-3 d-flex flex-column justify-content-center">
            <div class="qui-sommes-nous">
              <h2 class="qui-sommes-nous-title">{{$t('footer.quiSommes')}}</h2>
              <p class="text-white">
               {{$t('footer.quiSommesText')}}
              </p>
              <ul class="list-inline">
                <li class="icon-tel-footer"><img alt="Logo téléphone" src="@/assets/images/tel.webp"></li>
                <li class="tel-footer">+33 2 35 79 41 00</li>
              </ul>
            </div>
          </div>
          <div class="col-md-3">
            <ul class="list-inline list-menu">
              <li><a :href="`${websiteUrl}/actualite`">{{$t('actualites')}}</a></li>
              <li><a :href="`${websiteUrl}/vols-affaires`">{{$t('vols')}} </a></li>
              <li><a :href="`${websiteUrl}/occupation`">{{$t('occupation')}} </a>
              </li>
              <li><a :href="`${websiteUrl}/environnement`">{{$t('environnement')}}</a></li>
            </ul>
          </div>
          <div class="col-md-2">
            <ul class="list-inline list-menu">
              <li><a :href="`${websiteUrl}/tarifs`">{{$t('tarif')}}</a></li>
              <li><a :href="`${websiteUrl}/recrutement`">{{$t('recrutements')}}</a></li>
              <li><a :href="`${websiteUrl}/contact`">{{$t('contact')}}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-sm navbar-footer">
            <ul class="navbar-nav">
<!--              <li class="nav-item">-->
<!--                <a class="nav-link" href="#">{{$t('footer.politiques')}}</a>-->
<!--              </li>-->
              <li class="nav-item">
                <a :href="`${websiteUrl}/mentions`" class="nav-link">{{$t('footer.mentions')}}</a>
              </li>
              <li class="nav-item">
                <a :href="`${websiteUrl}/contact`" class="nav-link">{{$t('contactUs')}}</a>
              </li>
<!--              <li class="nav-item">-->
<!--                <a class="nav-link" href="#">{{$t('footer.plan')}}</a>-->
<!--              </li>-->
            </ul>
          </nav>
        </div>
        <div class="col-12 text-center"><a href="https://www.mcn-info.com/" target="_blank"><img
            alt="copyright" src="https://www.mcn-info.com/copyright-blanc.webp" title="copyright"></a></div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
export default {
  name: 'Footer',
  data() {
    return {
      websiteUrl: import.meta.env.VITE_WEBSITE_URL
    };
  },
};
</script>

<style lang="scss" scoped>
.bg-footer {
  background-color: #001b31;
  padding-top: 80px;
  padding-bottom: 90px;
  color: white;
  position: relative;
}

.bg-footer p {
  text-align: justify;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: .5rem;
  padding-right: 15%;
}

.footer1 {
  margin-bottom: 10px;
  border-bottom: 1px solid #ffffff3b;
}

.qui-sommes-nous {
  padding-right: 10%;
}

.bg-footer .qui-sommes-nous-title {
  font-weight: 800;
}

.icon-tel-footer {
  float: left;
  margin-right: 12px;
}

.tel-footer {
  font-family: kozgomedium;
  color: #d84018;
  font-size: 19px;
}

.list-menu {
  margin-top: 10px;
}

.list-menu li {
  line-height: 24px;
}

.list-menu li a {
  color: white;
  font-size: 18px;
}

.list-menu li a:hover {
  color: #d84018;
  text-decoration: none;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.navbar {
  padding: .2rem 5rem !important;
}

.navbar-footer {
  justify-content: center !important;
}

.navbar-footer ul li a {
  color: white;
  font-size: 12px !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (max-width: 767px) {
  .bg-footer {
    padding-top: 50px !important;

    .qui-sommes-nous {
      padding-right: 0;
      margin-top: 10px;

      .qui-sommes-nous-title {
        font-size: 20px;
      }
    }

    .list-menu {
      li {
        a {
          font-size: 15px;
        }
      }
    }

    .navbar-footer {
      padding: 0.2rem 1rem !important;
    }
  }
}

@media (max-width: 568px) {
  .bg-footer {
    padding-top: 35px !important;
    padding-bottom: 10px !important;

    img {
      height: 50px;
    }

    .qui-sommes-nous {
      padding-right: 0;
      margin-top: 10px;

      img {
        height: 25px;
      }

      .qui-sommes-nous-title {
        font-size: 20px;
      }

      .tel-footer {
        font-size: 14px;
      }
    }

    .list-menu {
      li {
        a {
          font-size: 13px;
        }
      }
    }

    .navbar-footer {
      padding: 0.2rem 1rem !important;

      .navbar-nav {
        flex-direction: row;

        li {
          a {
            padding: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 479px) {
  .navbar-footer {
    .navbar-nav {
      flex-direction: row;
      flex-wrap: wrap;

      li {
        width: 50%;
        flex: 0 0 auto;

        a {
          padding: 2px !important;
          font-size: 10px !important;
          text-align: center;
        }
      }
    }
  }
}


</style>
