import { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, default as AxiosStatic } from 'axios';

class AxiosFactory {
  public static create(customHeaders: Record<string, string> = {}): AxiosInstance {
    return AxiosStatic.create({
      headers: {...customHeaders},
      withCredentials: true
    });
  }

  public static getRequestHeaders(): AxiosRequestHeaders {
    const sessionId = window.sessionStorage.getItem('configurator_sess_id');

    if (sessionId !== null && sessionId.length === 0) {
      return {};
    }

    return {
      'X-Configurator-Session': sessionId ?? ''
    };
  }

  public static getRequestConfig(): AxiosRequestConfig {
    return {
      headers: AxiosFactory.getRequestHeaders()
    };
  }
}

export const axios = AxiosFactory.create();

export default AxiosFactory;
