import { TaxiType } from '@/models/TaxiType';
import { DeicingType } from '@/models/DeicingType';
import { DeicingProduct } from '@/models/DeicingProduct';

export interface InstanceServicesInterface {
  gpu: number;
  wcEmptying: boolean;
  luggageCart: number;
  elevatingCart: number;
  cleaning: boolean;
  deicingType: DeicingType;
  deicingProduct: DeicingProduct;
  taxiType: TaxiType;
  taxiPassengers: number;
  catering: string;
  runwayLighting: boolean;
}

export class InstanceServices implements InstanceServicesInterface {
  gpu = 0;
  wcEmptying = false;
  luggageCart = 0;
  elevatingCart = 0;
  cleaning = false;
  deicingType = DeicingType.None;
  deicingProduct = DeicingProduct.None;
  taxiType = TaxiType.None;
  taxiPassengers = 0;
  catering = '';
  runwayLighting = false;
}
