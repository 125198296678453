<template>
  <main class="flex-grow-1">
    <section class="section-configurateur">
      <div class="container-fluid">
        <div class="row">
          <div class="offset-md-1 col-md-10">
            <div class="div-configurateur">
              <div class="title-config">
                <h5>Vol d’affaires : je calcule mes coûts <span>(prix indicatifs)</span></h5>
              </div>
              <div class="form-config flex-secteur">
                <div class="w-100 form-padding d-flex flex-column align-items-center">
                  <span>Chargement en cours, veuillez patienter...</span>
                  <div class="spinner-border mt-3" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Loader'
});
</script>

<style lang="scss" scoped>

</style>
