import { Instance } from '@/models/Instance';
import { SanitizedPreloadQuery } from '@/models/SanitizedPreloadQuery';
import AxiosFactory, { axios } from '@/factory/AxiosFactory';
import { ApiResponse } from '@/models/ApiResponse';
import { Aircraft } from '@/models/Aircraft';
import Swal from 'sweetalert2';
import { isApiResponse } from '@/shared/Helpers';
import { Airport } from '@/models/Airport';

export class InstancePreloader {
  public static async preload(instance: Instance, preloadQuery: SanitizedPreloadQuery): Promise<Instance> {

    if (preloadQuery.aircraft.length) {
      await InstancePreloader.findAircraft(preloadQuery.aircraft).then((value: Aircraft | null) => {
        if (value !== null) {
          instance.aircraft = value;
          instance.seats = value.seats;
        }
      });
    }

    if (preloadQuery.seats > 0) {
      instance.passengers = preloadQuery.seats;
    }

    if (preloadQuery.departure.length) {
      await InstancePreloader.findAirport(preloadQuery.departure).then((value: Airport | null) => {
        if (value !== null) {
          instance.departure = value;
        }
      });
    }

    if (preloadQuery.destination.length) {
      await InstancePreloader.findAirport(preloadQuery.destination).then((value: Airport | null) => {
        if (value !== null) {
          instance.destination = value;
        }
      });
    }

    if (preloadQuery.arrivalDate !== null) {
      instance.arrivalDate = preloadQuery.arrivalDate;
    }

    if (preloadQuery.departureDate !== null) {
      instance.departureDate = preloadQuery.departureDate;
    }

    return JSON.parse(JSON.stringify(instance));
  }

  private static async findAircraft(code: string): Promise<Aircraft | null> {
    return axios.get(`${import.meta.env.VITE_API_URL}/aircraft/search/code/${code}`, AxiosFactory.getRequestConfig())
      .then(async ({data}: { data: ApiResponse | Aircraft }) => {
        if (isApiResponse(data)) {
          await Swal.fire({
            icon: 'error',
            text: data.message
          });

          return null;
        } else {
          return data;
        }
      });
  }

  private static async findAirport(code: string): Promise<Airport | null> {
    return axios.get(`${import.meta.env.VITE_API_URL}/airport/search/code/${code}`, AxiosFactory.getRequestConfig())
      .then(async ({data}: { data: ApiResponse | Airport }) => {
        if (isApiResponse(data)) {
          await Swal.fire({
            icon: 'error',
            text: data.message
          });

          return null;
        } else {
          return data;
        }
      });
  }
}
