import { createI18n } from 'vue-i18n';

const messages = {
  fr: {
    home: 'Home',
    aeroport: 'L\'aéroport',
    vols: 'Vols d\'affaires',
    occupation: 'Occupation du domaine public',
    environnement: 'Environnement',
    tarif: 'Tarifs',
    recrutements: 'Recrutement',
    contact: 'Accès et contact',
    actualites: 'Actualités',
    contactUs: 'Contactez-nous',
    savoirPlus: 'En savoir +',
    estimer : 'Estimer le coût de mon vol',
    footer: {
      quiSommes: 'Qui sommes-nous',
      quiSommesText : 'L’aéroport Rouen Vallée de Seine est au cœur de la Métropole Rouennaise, forte de 500 000 habitants dans un bassin de vie de 800 000 habitants avec 45 000 entreprises et 300 000 emplois dans les domaines industriels, tertiaires et portuaires.',
      politiques: 'Politique de confidentialité',
      mentions: 'Mentions légales',
      plan: 'Plan du site',
    },
    bandeau: {
      titre: 'Réserver un voyage avec l\'Aéroport de Rouen',
      description: 'Si vous souhaitez éviter les longues files d\'attente, la location d\'un avion d\'affaires est une excellente alternative aux lignes régulières traditionnelles. Vous n\'êtes alors plus dépendant des horaires des grandes compagnies aériennes et pouvez personnellement décider de  l\'heure du vol.',
    },
    avantages: {
      titre: 'Les avantages d\'un vol d\'affaires',
      icon1: 'Confort',
      icon2: 'Sécurité et confidentialité',
      icon3: 'Gain de temps',
      icon4: 'Terminaux privés et services sur-mesure VIP',
    },
    services: {
      titre: 'Les services d’assistance',
      texte: 'D\'autres aspects des services d\'assistance au sol des avions d\'affaires comprennent le ravitaillement en carburant du jet, le chargement des bagages, le nettoyage des cabines et des toilettes, l\'approvisionnement en eau douce ainsi que le service de repas frais à bord',
    },
    configTitles:{
      grostitre: 'Vol d\'affaires : je calcule mes coûts <span>(prix indicatifs)</span>',
      titre1: 'Étape 1: <span class="font_regular">l\'Aéronef</span>',
      titre2: 'Étape 2: <span class="font_regular">Provenance et destination</span>',
      titre3: 'Étape 3: <span class="font_regular">Arrivée et départ</span>',
      titre4: 'Étape 4: <span class="font_regular">Passagers</span>',
      titre5: 'Étape 5: <span class="font_regular">Prestations annexes</span>',
      soustitre1: 'Choix des services',
      titre6: 'Étape 6: <span class="font_regular">Options</span>',
      soustitre2: 'Options supplémentaires',
      titre7: 'Estimation',
      titre8: 'Détail de la tarification',
    },
    configBtn: {
      printBtn: 'Imprimer le récapitulatif',
      contactBtn: 'Contacter l\'aéroport',
      resetBtn: 'Réinitialiser le configurateur',
      nextBtn: 'Étape suivante',
    },
    configLabels: {
      type: 'Type d\'appareil',
      nbSiege: 'Nombre de sièges',
      provenance: 'Provenance',
      destination: 'Destination',
      arrivee: 'Arrivée',
      depart: 'Départ',
      nbPassager: 'Nombre de passagers',
      nbPassagHandic: 'Nombre de passagers handicapés',
      groupElectro: 'Groupe électrogène',
      tranche: 'Par tranche de 30 minutes',
      chariot: 'Chariots porte-baggages',
      nbChariot: 'Nombre',
      chariotElev: 'Chariots élévateur',
      nbChariotElev: 'Nombre',
      degivrage: 'Dégivrage',
      proDegivrage: 'Produit dégivrant',
      nettoyage: 'Nettoyage',
      videToilette: 'Vide toilettes',
      taxi: 'Service de Taxi',
      nbPassTaxi: 'Nombre de passagers',
      catering: 'Catering',
      oui: 'Oui',
      non: 'Non',
      aucun: 'Aucun',
      complet: 'Complet',
      partial: 'Partiel',
    },
    configInputs: {
      typePlace: 'Code, nom d\'appareil...',
      provenancePlace: 'Code ICAO/IATA, lieu...',
    },
    configResult: {
      aeronef: 'Aéronef',
      sieges: 'Sièges',
      passagers: 'Passagers',
      passagersHandic: 'Passagers handicapés',
      provenance: 'Provenance',
      Destination: 'Destination',
      arrivee: 'Date d\'arrivée',
      depart: 'Date de départ',
      tarif: 'Tarif estimé',
      taxe: 'Taxe d\'atterrissage',
      parking: 'Frais de parking',
      assistance: 'Frais d\'assistance',
      redevance: 'Redevance passagers',
    },
    configError: {
      type: 'Type d\'appareil',
      nbSiege: 'Le nombre de sièges',
      provenance: 'La provenance',
      destination: 'La destination',
      arrivee: 'La date d\'arrivée',
      depart: 'La date de départ',
      arrivaldate: 'La date de départ ne peut être avant la date d\'arrivée',
      nbPassager: 'Le nombre de passagers',
      nbPassagHandic: 'Le nombre de passagers handicapés',
      valideNumber: 'Veuillez saisir un nombre valide',
      groupElectro: 'Le nombre de demi-heures d\'utilisation du groupe électrogène',
      nbChariotElev: 'Le nombre de chartiots élévateurs',
      nbChariot: 'Le nombre de chariots porte-baggages',
      nbPassTaxi: 'Le nombre de passagers',
    },
    contactForm: {
      titre: 'Demande de contact',
      nom: 'Nom',
      ville: 'Ville',
      tel: 'N° de téléphone',
      send: 'Envoyer la demande',
    },
    contactError: {
      nom: 'Le nom',
      mail: 'L\' email',
      ville: 'La ville',
      tel: 'Le numéro de téléphone',
      msg: 'Votre message',
    }
  },
  en: {
    home: 'Home',
    aeroport: 'The airport ',
    vols: 'Business aviation',
    occupation: 'Occupation of public areas',
    environnement: 'Environment',
    tarif: 'Rates',
    recrutements: 'Recruitment',
    contact: 'Contact',
    actualites: 'News',
    contactUs: 'Contact us',
    savoirPlus: 'Find out more',
    estimer : 'Estimate the cost of my flight',
    footer: {
      quiSommes: 'About us',
      quiSommesText : 'Rouen Seine Valley Airport is at the heart of the Rouen metropolitan area, with 500,000 inhabitants in a population catchment area of 800,000 inhabitants (almost 25% of the region\'s population), 45,000 companies and 300,000 jobs in the industrial, services and port sectors.',
      politiques: 'Politique de confidentialité',
      mentions: 'Legal status',
      plan: 'Plan du site',
    },
    bandeau: {
      titre: 'Booking a flight with Rouen Airport',
      description: 'If you want to avoid long queues, renting a business jet is an excellent alternative to traditional commercial airlines. You no longer need to follow the schedule of the major airlines and can decide on your own flight time.',
    },
    avantages: {
      titre: 'The advantages of business aviation',
      icon1: 'Comfort',
      icon2: 'Security and privacy',
      icon3: 'Time saving',
      icon4: 'Private terminals and bespoke VIP services',
    },
    services: {
      titre: 'Assistance services',
      texte: 'Other aspects of the ground assistance services for business aviation include refuelling, loading baggage, cleaning cabins and toilets, supplying fresh water and fresh food to be served on board',
    },
    configTitles:{
      grostitre: 'Business aviation: calculate the price <span>(estimated price)</span>',
      titre1: 'Step 1: <span class="font_regular">Aircraft</span>',
      titre2: 'Step 2: <span class="font_regular">Departure city and destination</span>',
      titre3: 'Step 3: <span class="font_regular">Arrival and departure</span>',
      titre4: 'Step 4: <span class="font_regular">Passengers</span>',
      titre5: 'Step 5: <span class="font_regular">Extra services</span>',
      soustitre1: 'Choice of services',
      titre6: 'Step 6: <span class="font_regular">Options</span>',
      soustitre2: 'Additional options',
      titre7: 'Estimation',
      titre8: 'Pricing details',
    },
    configBtn: {
      printBtn: 'Print summary',
      contactBtn: 'Contact the airport',
      resetBtn: 'Reset configurator',
      nextBtn: 'Next step',
    },
    configLabels: {
      type: 'Aircraft type',
      nbSiege: 'Number of seats',
      provenance: 'Departure city',
      destination: 'Destination',
      arrivee: 'Arrival',
      depart: 'Departure',
      nbPassager: 'Number of passengers',
      nbPassagHandic: 'Number of disabled passengers',
      groupElectro: 'Generator',
      tranche: 'In 30-minute increments',
      chariot: 'Luggage trolleys',
      nbChariot: 'Number',
      chariotElev: 'Forklifts',
      nbChariotElev: 'Number',
      degivrage: 'De-icing',
      proDegivrage: 'De-icing product',
      nettoyage: 'Cleaning',
      videToilette: 'Emptying toilets',
      taxi: 'Taxi service',
      nbPassTaxi: 'Number of seats',
      catering: 'Catering',
      oui: 'Yes',
      non: 'No',
      aucun: 'None',
      complet: 'Complete',
      partial: 'Partial',
    },
    configInputs: {
      typePlace: 'Code, name of the aircraft...',
      provenancePlace: 'Code ICAO/IATA, location...',
    },
    configResult: {
      aeronef: 'Aircraft',
      sieges: 'Seats',
      passagers: 'Passengers',
      passagersHandic: 'Disabled passengers',
      provenance: 'Departure city',
      destination: 'Destination',
      arrivee: 'Arrival',
      depart: 'Departure',
      tarif: 'Estimated price',
      taxe: 'Landing fee',
      parking: 'Parking fee',
      assistance: 'Assistance fee',
      redevance: 'Passenger tax',
    },
    configError: {
      type: 'The aircraft type',
      nbSiege: 'The number of seats',
      provenance: 'Departure city',
      destination: 'Destination',
      arrivee: 'Arrival',
      depart: 'Departure',
      arrivaldate: 'The departure date cannot be earlier than the arrival date',
      nbPassager: 'Number of passengers',
      nbPassagHandic: 'Number of disabled passengers',
      valideNumber: 'Please enter a valid number',
      groupElectro: 'The number of half-hours the generator is in use',
      nbChariotElev: 'Number of Forklifts',
      nbChariot: 'Number of Luggage trolleys',
      nbPassTaxi: 'Number of passengers',
    },
    contactForm: {
      titre: 'Contact us',
      nom: 'Name',
      ville: 'City',
      tel: 'Phone number',
      send: 'Send request',
    },
    contactError: {
      nom: 'Name',
      mail: 'Email',
      ville: 'City',
      tel: 'Phone number',
      msg: 'Your message',
    }
  }
};

const i18n = createI18n({
  legacy: false,
  locale: 'fr',
  fallbackLocale: 'en',
  globalInjection: true,
  messages,
});

export default i18n;
