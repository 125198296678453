import { PreloadQuery } from '@/models/PreloadQuery';
import { SanitizedPreloadQuery } from '@/models/SanitizedPreloadQuery';
import { parseDate } from '@/shared/Helpers';

export class QueryParser {
  public static parse(query: Record<string, string>): PreloadQuery {
    return {
      aircraft: query.aircraft || '',
      seats: query.seats || '',
      departure: query.departure || '',
      destination: query.destination || '',
      departureDate: query.departureDate || '',
      arrivalDate: query.arrivalDate || '',
    };
  }

  public static sanitize(query: PreloadQuery): SanitizedPreloadQuery {
    let aircraft = '';
    let seats = 0;
    let departure = '';
    let destination = '';
    let departureDate = null;
    let arrivalDate = null;

    if (query.aircraft.length) {
      aircraft = query.aircraft.replace(/\W/g, '');
    }

    if (query.seats.length) {
      seats = parseInt(query.seats);
    }

    if (query.departure.length) {
      departure = query.departure.replace(/\W/g, '');
    }

    if (query.destination.length) {
      destination = query.destination.replace(/\W/g, '');
    }

    if (query.departureDate.length) {
      departureDate = parseDate(query.departureDate, 'dd/mm/yyyy hh:ii');
    }

    if (query.arrivalDate.length) {
      arrivalDate = parseDate(query.arrivalDate, 'dd/mm/yyyy hh:ii');
    }

    return {
      aircraft,
      seats,
      departure,
      destination,
      departureDate,
      arrivalDate
    };
  }
}
