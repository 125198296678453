import App from './App.vue';
import { routes } from './router/routes';

import { viteSSR } from 'vite-ssr/vue';
import { HookParams } from 'vite-ssr/vue/types';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import * as Sentry from '@sentry/vue';

import type { App as VueApp } from 'vue';
import { Router } from 'vue-router';

import i18n from './translation/i18n';

const options = {
  routes: routes,
  base: (): string => import.meta.env.VITE_BASE
};


export default viteSSR(App, options, (context: HookParams) => {
  /* Vite SSR main hook for custom logic */
  const {app, router}: { app: VueApp, router: Router } = context;

  Sentry.init({
    app,
    release: import.meta.env.VITE_APP_VERSION,
    dsn: 'https://4c4eb9c4de344404af5651f72904f997@o384347.ingest.sentry.io/4504485285134336',
    integrations: [
      Sentry.browserTracingIntegration(({router})),
    ],
    tracesSampleRate: 1.0,
    logErrors: true
  });

  app.use(VueSweetalert2);

  app.component('Datepicker', Datepicker);
	
  app.use(i18n);
});
