<template>

  <div class="header-rd page_inside" v-bind:class="[isSticky ? stickyClass : '']">
    <div class="info-phone">
      <a class="btn btn-primary btn-estimer" href="/">{{$t('estimer')}}</a>
      <ul class="list-inline list-color">
        <li></li>
        <li class="phone-header"><a href="tel:+33235794100"><img alt="tél" src="@/assets/images/tel.webp"></a></li>
        <li class="pdR20"><a href="tel:+33235794100">+33 2 35 79 41 00</a></li>
      </ul>
			
			<div class="selectLangue">
          <div class="langue_item ">
            <b-button type="submit" @click="changeLocale('fr')" class="pointer"><img src="@/assets/images/langue-fr.png" alt="fr" ></b-button>
          </div>
          <div class="langue_item ">
            <b-button type="submit" @click="changeLocale('en')" class="pointer"><img src="@/assets/images/langue-en.png" alt="en" ></b-button>
          </div>
      </div>

    </div>
    <nav class="navbar navbar-expand-lg navbar-light ">
      <a :href="websiteUrl" class="navbar-brand"><img alt="logo Aeroport Rouen Boss" class="this_logo"
                                                      src="@/assets/images/logo-aeroport-rouen.webp"></a>

      <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              class="navbar-toggler"
              data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>


      <div id="navbarSupportedContent" class="collapse navbar-collapse">
        <ul class="navbar-nav mr-auto navbar-t navbar-nav-scroll">
          <li class="nav-item">
            <a :href="websiteUrl" class="nav-link"><i aria-hidden="true"
                                                      class="fa fa-home"></i></a>
          </li>
          <li class="nav-item">
            <a :href="`${websiteUrl}/aeroport`" class="nav-link">{{$t('aeroport')}}</a>
          </li>
          <li class="nav-item">
            <a :href="`${websiteUrl}/vols-affaires`" class="nav-link">{{$t('vols')}}</a>
          </li>
          <li class="nav-item">
            <a :href="`${websiteUrl}/occupation`" class="nav-link">{{$t('occupation')}}</a>
          </li>
          <li class="nav-item">
            <a :href="`${websiteUrl}/environnement`" class="nav-link">{{$t('environnement')}}</a>
          </li>
          <li class="nav-item">
            <a :href="`${websiteUrl}/tarifs`" class="nav-link">{{$t('tarif')}}</a>
          </li>
          <li class="nav-item">
            <a :href="`${websiteUrl}/recrutement`" class="nav-link">{{$t('recrutements')}}</a>
          </li>
          <li class="nav-item">
            <a :href="`${websiteUrl}/contact`" class="nav-link">{{$t('contact')}}</a>
          </li>
        </ul>
      </div>

      <div class="info-phone-sticky">
        <ul class="list-inline list-color">
          <li class="phone-header"><a href="tel:+33235794100"><img alt="tél" src="@/assets/images/tel.webp"></a></li>
          <li class="pdR20"><a href="tel:+33235794100">+33 2 35 79 41 00</a></li>
        </ul>
      </div>

    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    status: {
      type: String,
      default: '',
    },
  },
  emits: [
    'onLanguageChanged'
  ],
  data() {
    return {
      websiteUrl: import.meta.env.VITE_WEBSITE_URL,
      isSticky: false,
      stickyClass: 'sticky',
      scrollPosition: 0
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY;
      this.isSticky = this.scrollPosition >= 200;
    },
    changeLocale(locale: string) {
      this.$emit('onLanguageChanged', locale);
    }
  }
});
</script>

<style lang="scss" scoped>
.transition_div {
  position: fixed;
  top: 50px;
  right: 30px;
  background-color: #fff;
  z-index: 5;
}

.transition_div span {
  padding: 10px;
}

.bg-dark {
  background-color: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(8px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.mr-4 {
  margin-right: .45em;
}

.navbar {
  padding: 0.2rem 5rem !important;
}

.header-rd.page_inside {
  position: relative;
  background-color: #fff;
  width: 100%;
  box-shadow: rgb(149 157 165 / 20%) 0 8px 24px;
}

.header-rd {
  z-index: 999;
  transition: all .4s;
}

.header-rd.page_inside .navbar-light .navbar-nav .nav-link {
  color: #003b6c !important;
  font-weight: 700;
  padding-right: 15px !important;
  font-size: 17px;
}

.info-phone {
  display: flex;
  justify-content: right;
}

.phone-header img {
  margin-bottom: 5px;
}

.list-color {
  background-color: #d84018 !important;
  display: -webkit-inline-box;
  border-radius: 40px;
  margin-right: 8%;
  position: relative;
  top: 45px;
  right: -3%;
  z-index: 3;
  transition: all .2s linear;
}

.list-color:hover {
  background-color: #003b6b !important;
}

.list-color li a {
  color: white !important;
  font-weight: 700;
  line-height: 40px;
  padding-left: 20px;
  text-decoration: none;
}

.pdR20 {
  padding-right: 20px;
}

.navbar-t {
  position: absolute;
}

.header-rd.page_inside .navbar-light .navbar-nav .nav-link i {
  font-size: 24px;
}

.navbar-collapse {
  justify-content: right;
}

.header-rd.page_inside .navbar-light .navbar-nav .nav-link:hover {
  color: #d84018 !important;
}

.btn-estimer {
  background-color: white !important;
  border-color: #2db5df !important;
  color: #2db5df !important;
  font-family: kozgomedium;
  border-radius: 20px !important;
  margin-top: 13px !important;
  position: relative;
  top: 30px;
  right: -3%;
  z-index: 3;
  margin-right: 15px;
  padding: 0.375rem 0.75rem !important;
}

.header-rd.page_inside.sticky {
  position: fixed;
}

.header-rd.sticky .info-phone {
  display: none;
}

.header-rd.page_inside.sticky .this_logo {
  height: 65px;
}

.header-rd .info-phone-sticky {
  display: none;
}

.header-rd.sticky .info-phone-sticky {
  display: block;
  margin-left: 25px;
}

.header-rd.sticky .info-phone-sticky .list-color {
  top: auto;
  right: auto;
  margin-top: 12px;
}

.header-rd.sticky .info-phone-sticky .list-color li:last-child {
  margin-right: 15px;
}

.header-rd.sticky .info-phone-sticky .list-color li:first-child a {
  padding-left: 15px;
}

.selectLangue{
	position: relative;
    top: 35px;
    right: 50px;
    display: flex;
    align-items: center;
		z-index:2;
}

.selectLangue .langue_item .pointer{
	display: block;
    margin: 5px;
		
}
.selectLangue .langue_item.active .pointer{
	opacity:1;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.selectLangue .langue_item img{
	height:22px;
}

@media only screen and (max-width: 1380px) {
  .navbar {
    padding: 0.2rem 2rem !important;
  }
  .header-rd.page_inside .navbar-light .navbar-nav .nav-link {
    font-size: 15px;
    padding-right: 10px !important;
  }
  .header-rd.page_inside .navbar-light .navbar-nav .nav-link i {
    font-size: 18px;
  }
  .header-rd.sticky .info-phone-sticky {
    margin-left: 0;
  }
  .header-rd.sticky .info-phone-sticky .list-color li:last-child {
    margin-right: 0;
  }
  .header-rd.sticky .info-phone-sticky .list-color {
    margin-right: 0;
  }
  .header-rd.sticky .info-phone-sticky .list-color li:first-child a {
    padding-left: 5px;
  }
  .header-rd.sticky .info-phone-sticky .list-color li:last-child a {
    padding-left: 5px;
    font-size: 12px;
  }
  .header-rd.sticky .info-phone-sticky .pdR20 {
    padding-right: 8px;
  }
}

@media only screen and (max-width: 1199px) {
  .navbar {
    padding: 0.2rem 1rem !important;
  }
  .navbar-brand img {
    height: 70px;
  }
  .list-color {
    margin-right: 1%;
    top: 10px;
    right: 0;
  }
  .list-color li a {
    padding-left: 10px;
  }
  .pdR20 {
    padding-right: 16px;
  }
  .header-rd.page_inside .navbar-light .navbar-nav .nav-link {
    font-size: 14px;
    padding-right: 5px !important;
  }
  .transition_div {
    top: 10px;
    right: 15px;
  }
  .header-rd.page_inside .navbar-light .navbar-nav .nav-link i {
    font-size: 16px;
  }
  .btn-estimer {
    top: 10px;
    margin-top: 0px !important;
    right: 0%;
  }
  .header-rd.sticky .info-phone-sticky .list-color li:last-child {
    display: none;
  }
  .header-rd.sticky .info-phone-sticky .list-color li:first-child a {
    padding-left: 7px;
    padding-right: 7px;
  }
  .header-rd.sticky .info-phone-sticky .list-color {
    border-radius: 100px;
    margin-top: -8px;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-t {
    position: relative;
  }
  .navbar-toggler {
    padding: 5px;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .list-color {
    margin-bottom: 0px;
  }
  .list-color li a {
    font-size: 13px;
  }
  .phone-header img {
    margin-bottom: 2px;
    height: 22px;
  }
  .btn-estimer {
    font-size: 13px;
    margin-right: 8px;
  }
  .header-rd.sticky .info-phone-sticky {
    display: none;
  }
  .header-rd .info-phone {
    display: flex;
  }

}

@media only screen and (max-width: 767px) {
  .transition_div span {
    font-size: 12px;
  }
  .transition_div {
    top: 5px;
    right: 5px;
    width: 65%;
  }
}

@media (max-width: 568px) {
  .transition_div span {
    font-size: 10px;
  }
  .navbar-brand img {
    height: 55px;
  }
  .navbar {
    padding: 0.2rem 0.3rem !important;
  }
  .list-color li a {
    font-size: 11px;
    line-height: 30px;
    padding-left: 6px;
  }
  .pdR20 {
    padding-right: 8px;
  }
  .navbar-toggler-icon {
    width: 1em;
    height: 1em;
  }
  .btn-estimer {
    font-size: 10px;
    margin-right: 4px;
  }
  .info-phone {
    padding-bottom: 10px
  }
  .header-rd.page_inside.sticky[data-v-61dd7a3d] {
    position: relative;
  }
}


</style>
